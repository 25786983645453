<template>
  <ui-component-modal
    :modalTitle="$t('Components.Events.EventDescription.Edit.Main_Title')"
    modalSize="large"
    :disableSaveButton="!mEventData.Description.length"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :onClickSave="saveEventDescription"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooter="isSavingError || isSavingSuccess"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <message
        v-if="!mEventData.Description.length"
        type="is-danger"
        :message="
          $t('Components.Events.EventDescription.Edit.Error_NoDescriptionGiven')
        "
        class="has-marign-bottom"
      />
      <text-editor v-model="mEventData.Description"></text-editor>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations } from 'vuex'
import eventProvider from '@/providers/event'
const Message = () => import('@/components/UI/Message')
const TextEditor = () => import('@/components/UI/TextEditor')
export default {
  components: {
    'text-editor': TextEditor,
    message: Message,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      mEventData: this.$objectHelper.cleanSource(
        this.$store.getters['eventStore/getEventData']
      ),
    }
  },
  methods: {
    ...mapMutations('eventStore', ['setEventData']),
    saveEventDescription() {
      let self = this

      // Save Call
      if (!this.isSaving) {
        this.isSaving = true

        eventProvider.methods
          .updateEvent(this.mEventData)
          .then((response) => {
            if (response.status === 200) {
              self.setEventData(response.data)
              self.isSavingSuccess = true

              setTimeout(() => {
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch((e) => {
            //self.$//console.info(e)
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
